@import '../../global.scss';

.epoch-page {
    // margin: 2rem 0;
    // padding: 1rem 0;
    .epoch-section {
        //opacity: 0;
        transition: opacity 0.55s;
        &.show {
            opacity: 1;
        }
    }
    p {
        a {
            color: #837a69;
        }
    }
.tab-epoch-wrapper{
    position: relative;
    overflow: hidden;
    background-image: url('../../image/stone-dark.png');
    transition: height 2s;
    min-height: 900px;
    padding-bottom: 4rem;
    &:after {
        content : '';
        background: rgb(0,0,0);
        background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0) 100%);
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        height: 50px;
        z-index: 300;
    }
    .ink {
        z-index: 0;
        width: 150%;
        position: absolute;
        left: 50%;
        top: 30px;
        transform:  translate3d(-50%,-50%, 0);
       
        img {
            width: 100%;
        }

    }
    .epoch-section {
        z-index: 500;
       // position: absolute;
       // top: 0;
       // left: 0;
       // right: 0;
    }
}
   


    .tab-nav {
        margin: 0 auto;
            max-width: 1200px;
        position: relative;
        &:after {
            content: '';
            background: rgb(0,0,0);
            background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0) 100%);
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            height: 50px;
            z-index: 100;
        }
        .underline {
            position: absolute;
            transition: transform 0.3s ease-out;
            content: '';
            width: 16.66%;
            height: 2px;
            display: block;
            bottom: 0;
            background: rgb(116,91,47);
            z-index: 400;
background: linear-gradient(90deg, rgba(116,91,47,0) 0%, rgba(116,91,47,1) 30%, rgba(116,91,47,1) 70%, rgba(116,91,47,0) 100%);
        }
        ul {
            padding: 0;
            
            list-style-type: none;
            font-size: 0;
            position: relative;
            z-index: 200;
            
            li {
                display: inline-block;
                font-size:1.5rem;
                width: 16.66%;
                text-align: center;
                position: relative;
                padding: 0.5rem 0;
                @include media('<=phone') {
                    font-size: 1rem; 
                    padding: 0.25rem 0;
                }
                a {
                    text-decoration: none;
                    color: #fff;
                    text-transform: uppercase;
                    position: relative;
                    display: block;
                    cursor: pointer;
                }
            }
        }
    }

    
}