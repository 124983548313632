@import '../../../../global.scss';

.block-board-manager {
    .supply-filters {
        margin-top: -1rem;
    margin-bottom: 1rem;
    @include media('<=1000px') {
        margin-top: 0;;
     }
    }


    .board-empty {
        font-family: 'Nanum Gothic Coding', monospace;
        color: #666;
        line-height: 1.4rem;
        margin-bottom: 1rem;
    }
    


    .supply-filter {
        display: inline-block;
        margin-right: 1rem;
        label {
            position: relative;
            font-family: 'Nanum Gothic Coding', monospace;
            color: #bababa;
            text-transform: uppercase;
            font-size: 0.9rem;
            @include media('<=1000px') {
                font-size: 0.75rem;
             }
             cursor: pointer;
        }
        input[type="checkbox"] {
            opacity: 0;
            position: absolute;
        }
        
        .check-box {
            width: 16px;
            height: 16px;
            display: inline-block;
            background-color: #111;
            vertical-align: bottom;
            border: 3px solid #000;
            box-sizing: border-box;
            opacity: 0.6;
            margin-right: 0.3rem;
        }
        .active {
            .check-box {
                background-color: #4e4631;
               
            }
        }
    }
    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        gap: 1rem;
        @include media('<=1000px') {
            grid-template-columns: 1fr 1fr;
            
        }
        li {
            position: relative;
        }
    }
    .board-item {
        height: 100%;
        margin-bottom: 36px;
        padding-bottom: 1rem;
        .owned-by {
            opacity: 0.5;
            display: block;
            margin-top: 0.5rem;
            @include media('<=1000px') {
                font-size: 0.9rem;
            }
            .special {
                display: inline-block;
                vertical-align: middle;
                margin-right: 0.25rem;
                svg {
                    width: 16px !important;
                    height: 16px !important;
                }
            }
            &.is-user {
                opacity: 1;
                color: #a19679;
                svg {
                    path {
                        fill: #a19679;
                    }
                }
            }
            &.not-user {
                text-decoration:line-through;
            }
        }
        &.disabled {
            pointer-events: none;
        }
        .button-footer {
            position: absolute;
            bottom: 0;
            text-align: right;
            width: 100%;
        }
        .mint-button, .pending-mint-button, .expired-mint-button {
            display: inline-block;
            background-color: #4e4631;
            border: 1px solid #746849;
            color: #FFF;
            font-family: 'Nanum Gothic Coding', monospace;
            text-transform: uppercase;
            padding: 0.5rem 1rem;
            cursor: pointer;
            font-size: 1rem;
            border-radius: 2px;
            transition: border 0.25s, background-color 0.25s;
            box-shadow: 0 0 14px -2px #000;
            @include media('<=1000px') {
                padding: 0.5rem 0.5rem;
                 
              }
            &:hover {
                border: 1px solid #94845c;
                background-color: #685d42;
            }
            &:disabled {
                opacity: 0.5;
                pointer-events: none;
            }
        }
    
        .pending-mint-button {
            opacity: 0.5;
        pointer-events: none;
            display: inline-block;
            div {
                display: inline-block;
            }
        }
        .expired-mint-button {
            opacity: 0.5;
        pointer-events: none;
            display: inline-block;
            background-color: #222;
            border: 1px solid #333;
            div {
                display: inline-block;
            }
        }
    }
    .slot-power-glow {
        display: none;
    }
    .board-slot {
        position: relative;
        .slot-hit-box {
            &.open {
                cursor: pointer;
            }
        }
        button {
            height: 36px;
            width: 36px;
            padding: 0;
            border: 0;
            opacity: 0.75;
            background-color: #000;
            border-radius: 100%;
            top: -18px;
            right: -18px;
            position: absolute;
            cursor: pointer;
            @include media('<=1000px') {
                height: 24px;
                width: 24px;
                top: -12px;
                right: -12px;
                
            }
            svg {
                width: 24px;
                height: 24px;
                @include media('<=1000px') {
                    width: 16px;
                    height: 16px;
                }
                path {
                    fill: #ccc;
                }
            }
        }
    }
    
    
}