@import '../../global.scss';

.figure-detail {
    margin-top: 0.5rem;
    span {
        display: block;
    };
    .figure-name {
        font-size: 1.2rem;
        color: #fff;
        @include media('<=tablet') {
            font-size: 0.9rem;
         }
    }
    .figure-desc {
        font-size: 1.2rem;
        color: #fff;
        margin-bottom: 0.25rem;
        @include media('<=tablet') {
            font-size: 0.9rem;
         }
    }
    .figure-artist {
        color: #bababa;
        margin-bottom: 0.25rem;
        @include media('<=tablet') {
            font-size: 0.75rem;
         }
    }
    .figure-element {
        position: relative;
        box-sizing: border-box;
        padding-left: 16px;
        &:after {
            content: '';
            position: absolute;
            top: 3px;
            left: 0px;
            width: 10px;
            height: 10px;
            display: block;
            border-radius: 100%;
            box-shadow: inset 0px 2px 5px -2px #fff;
            @include media('<=tablet') {
                top: 2px;
             }
        }
    }
    .figure-bottom {
        display: grid;
        font-family: 'Nanum Gothic Coding', monospace;
        grid-template-columns: 1fr 1fr;
        font-size: 0.9rem;
        color: #666;
        @include media('<=tablet') {
            font-size: 0.75rem;
         }
        .reward-token-edition {
            text-align: right;
        }
    }

    .figure-rates {
        font-family: 'Nanum Gothic Coding', monospace;
        margin-top: 0.5rem;
        font-size: 0.9rem;
        color: #666;
        @include media('<=tablet') {
            font-size: 0.75rem;
         }
    }

    .element-1 {
        &:after {
            background-color: $colorDeath;
        }
        
    }
    .element-2 {
        &:after {
        background-color: $colorLife;
    }
    }
    .element-3 {
        &:after {
        background-color: $colorFire;
    }
    }
    .element-4 {
        &:after {
        background-color: $colorWater;
    }
    }
    .element-5 {
        &:after {
        background-color: $colorEarth;
    }
    }
    .element-6 {
        &:after {
        background-color: $colorAir;
    }
    }
    .element-7 {
        &:after {
        background-color: $colorMemory;
    }
    }
    .element-8 {
        &:after {
        background-color: $colorVision;
    }
    }
}