@import '../../../../global.scss';

.block-board-history {
    margin-top: 1rem;
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 1rem;
        @include media('<=1000px') {
            grid-template-columns: 1fr 1fr 1fr 1fr;
            
        }
    }
    .block-board-history-item {
        color: #bababa;
        display: grid;
        grid-template-columns: 1fr;
        gap: 0.5rem;
        text-align: center;
        position: relative;
        @include media('<=1000px') {
            font-size: 0.85rem;
        }
    }
    .is-user {
        color: #a19679;
        .special {
            opacity: 1;
            svg {
                path {
                    fill: #a19679;
                }
            }
        }
    }
    .special {
        position: absolute;
        opacity: 0.5;
        top: -9px;
        left: -9px;
        svg {
            width: 16px !important;
            height: 16px !important;
        }
    }
}