@import '../../global.scss';

.supply-page {
    background: url('../../image/stone-dark.png');
    min-height: 100%;
    .page-content-wrapper {
        box-shadow: 0 0 60px 0px #000;
        position: relative;
        &:after {
            content: '';
    position: absolute;
    top: 0px;
    left: 0;
    height: 2px;
    background-image: url('../../image/border-top.png');
    display: block;
    width: 100%;
    opacity: 0.5;
        }
        &:before {
            content: '';
    position: absolute;
    bottom: 0px;
    left: 0;
    height: 2px;
    background-image: url('../../image/border-top.png');
    display: block;
    width: 100%;
    opacity: 0.5;
        }
    }
    .tier-nav {
        margin: 1rem 0;
        overflow: hidden;
        &.size-3 {
            span {
                width: 33.33%;
            }
        }
        &.size-4 {
            span {
                width: 25%;
            }
        }
        .tier-nav-wrapper {
            display: grid;
        
        &.size-3 {
            grid-template-columns: 1fr 1fr 1fr;
            
        }
        &.size-4 {
            grid-template-columns: 1fr 1fr 1fr 1fr;
            
        }
            button {
                background-color: transparent;
                width: 100%;
                text-align: center;
                border: 0;
                color: #ccc;
                cursor: pointer;
                font-family: 'Cormorant', serif;
                font-size: 1.4rem;
                text-transform: uppercase;
                line-height: 2.5rem;
                @include media('<=1000px') {
                    font-size: 1.2rem;
                    line-height: 2rem;
                }
            }
        }
        span {
            

            transition: transform 0.3s ease-out;
                    
            
                    height: 2px;
                    display: block;
                   
                    background: #745b2f;
                    z-index: 400;
                    background: linear-gradient(
                90deg
                , rgba(116, 91, 47, 0) 0%, #745b2f 30%, #745b2f 70%, rgba(116, 91, 47, 0) 100%);
                    
        }
        
    }
    .supply-tier {
        padding-top: 1rem;
        > ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            display: grid;
           // grid-template-columns: repeat( auto-fit, minmax(256px, 1fr) );
           grid-template-columns: 1fr 1fr 1fr;
           @include media('<=1000px') {
               grid-template-columns: 1fr 1fr;
               gap: 1rem;
            }
           gap: 2rem;
        }
        
    }

    .supply-filter {
        label {
            position: relative;
            font-family: 'Nanum Gothic Coding', monospace;
            color: #bababa;
            text-transform: uppercase;
            font-size: 0.9rem;
            @include media('<=1000px') {
                font-size: 0.75rem;
             }
             cursor: pointer;
        }
        input[type="checkbox"] {
            opacity: 0;
            position: absolute;
        }
        
        .check-box {
            width: 16px;
            height: 16px;
            display: inline-block;
            background-color: #000;
            vertical-align: bottom;
            border: 3px solid #000;
            box-sizing: border-box;
            opacity: 0.6;
            margin-right: 0.3rem;
        }
        .active {
            .check-box {
                background-color: #4e4631;
               
            }
        }
    }
    .supply-empty {
        width: 80%;
        margin: 0 auto;
        text-align: center;
        box-sizing: border-box;
        padding: 4rem 0;
        min-height: 600px;
       
        h2 {
            font-size: 1.6rem;
            font-weight: 500;
            color: #bababa;
        }
    }
}