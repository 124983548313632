@import '../../global.scss';

.figure-select {
    
    position: fixed;
    z-index: 30001;
    bottom: 0;
    height: 100vh;
    right: 0;
    width: 600px;
    box-shadow: 0 0 57px 8px #000;
    box-sizing: border-box;
    background-image: url('../../image/stone-dark.png');
    @include media('<1000px') {
        height: 95vh;
        width: 100%;
        box-shadow: 0px -35px 57px 0px #000;
    }
    .figure-select-close {
        position: absolute;
        width: 48px;
        height: 48px;
        left: -48px;
        @include media('<1000px') {
            left: auto;
            right: 0;
            top: -24px;
            width: 24px;
                height: 24px;
        }
        button {
            width: 48px;
            height: 48px;
            background-color: transparent;
            border: 0;
            padding: 0;
            @include media('<1000px') {
                width: 24px;
                height: 24px;
            }
            cursor: pointer;
            svg {
                path {
                    fill: #5e5645;
                }
            }
        }
    }
    .figure-scroll-wrapper {
        height: 100%;
        overflow: scroll;
        padding: 1rem;
    box-sizing: border-box;
    }
    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: grid;
        grid-template-columns: 1fr 1fr;
        @include media('<1000px') {
            grid-template-columns: 1fr 1fr;
        }
        gap: 1rem;
        li {
            
        }
    }

    
@media (min-width: 901px) {
    .figure-select-item.element-1:hover {
        .figure-display:after {
            border: 7px solid #000;
            -webkit-box-shadow: inset 0 0 10px 10px #000;
                    box-shadow: inset 0 0 10px 10px #000;
        }
    }
  }
  
  @media (min-width: 901px) {
    .figure-select-item.element-2:hover {
        .figure-display:after {
                border: 7px solid #FFF;
            -webkit-box-shadow: inset 0 0 10px 10px #FFF;
              box-shadow: inset 0 0 10px 10px #FFF;
        }
    }
  }
  
  @media (min-width: 901px) {
    .figure-select-item.element-3:hover {
        .figure-display:after {
      border: 7px solid #b60000;
      -webkit-box-shadow: inset 0 0 10px 10px #b60000;
              box-shadow: inset 0 0 10px 10px #b60000;
        }
    }
  }
  
  @media (min-width: 901px) {
    .figure-select-item.element-4:hover{
        .figure-display:after {
      border: 7px solid #383fa3;
      -webkit-box-shadow: inset 0 0 10px 10px #383fa3;
              box-shadow: inset 0 0 10px 10px #383fa3;
        }
    }
  }
  
  @media (min-width: 901px) {
    .figure-select-item.element-5:hover {
        .figure-display:after {
      border: 7px solid #0d5016;
      -webkit-box-shadow: inset 0 0 10px 10px #0d5016;
              box-shadow: inset 0 0 10px 10px #0d5016;
        }
    }
  }
  
  @media (min-width: 901px) {
    .figure-select-item.element-6:hover {
        .figure-display:after {
      border: 7px solid #7a7a7a;
      -webkit-box-shadow: inset 0 0 10px 10px #7a7a7a;
              box-shadow: inset 0 0 10px 10px #7a7a7a;
        }
    }
  }
  
  @media (min-width: 901px) {
    .figure-select-item.element-7:hover {
        .figure-display:after {
      border: 7px solid #b64a02;
      -webkit-box-shadow: inset 0 0 10px 10px #b64a02;
              box-shadow: inset 0 0 10px 10px #b64a02;
        }
    }
  }
  
  @media (min-width: 901px) {
    .figure-select-item.element-8:hover {
        .figure-display:after {
      border: 7px solid #490755;
      -webkit-box-shadow: inset 0 0 10px 10px #490755;
              box-shadow: inset 0 0 10px 10px #490755;
        }
    }
  }

  .figure-select-item {
      cursor: pointer;
      position: relative;
      .figure-display {
          position: relative;
      }
  };

  .figure-select-item {
      .figure-display:after {
    opacity: 0.4;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 1px solid rgba(0, 0, 0, 0);
    -webkit-transition: border 0.35s, -webkit-box-shadow 0.2s;
    transition: border 0.35s, -webkit-box-shadow 0.2s;
    transition: border 0.35s, box-shadow 0.2s;
    transition: border 0.35s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
    -webkit-box-shadow: inset 0 0 0 0 #FFF;
            box-shadow: inset 0 0 0 0 #FFF;
        }
  }
}