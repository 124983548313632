@import '../../global.scss';

.user-page {
    height: 100%;
    position: relative;
    overflow: hidden;
    background-image: url('../../image/stone-dark.png');
    &:before {
        content: "";
        
      //background-size: contain;
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      //opacity: 0.35;
      box-shadow: inset 0 0 147px 17px #000;
      pointer-events: none;
    }
    .elements {
      padding: 2rem;
      gap: 0.5rem;
      position: absolute;
      bottom: 0;
      right: 0;
      display: grid;
      grid-template-columns: 1fr ;
    }
    .connect-area {
      width: 75%;
    height: 75%;
    background-color: transparent;
    position: absolute;
    z-index: 1100;
    border: 0;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%,-50%, 10px);
    cursor: pointer;
    }
    .circle-connect {
      width: 100%;
      max-width: 724px;
      
    position: absolute;
    z-index: 1100;
    
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    cursor: pointer;
      img {
        width: 100%;
        
      }
    }
    .circle-options-wrapper {
      display: flex;
      justify-content: center;
  position: absolute;
      bottom: 0;
      width: 100%;
      
      .axis-button {
        width: 36px;
    height: 36px;
    display: block;
    position: relative;
    background-color: #0000008a;
    border-radius: 100%;
    border: 2px solid #292929;
    box-sizing: border-box;
        svg {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate3d(-50%, -50%, 0);
          path {
            transition: fill 0.25s ease;
            fill: #bababa;
          }
        }
        &:hover {
          svg {
            path {
              fill: #fff;
            }
          }
        }
      }

    }
    .circle-options {
      padding: 0 1rem 0.5rem;
      box-sizing: border-box;
      width: 100%;
      max-width: 300px;
      align-items: center;

      text-align: center;
      display: grid; 
      grid-template-columns: 36px 1fr 36px;
      @include media('<=1000px') {
        max-width: 100%;
      }
      button {
        font-family: 'Cormorant', serif;
        cursor: pointer;
        background-color: transparent;
        border: 0;
        padding: 0;
        text-align: center;
      font-size: 1.6rem;
      line-height: 64px;
      color: #bababa;
      margin: 0 auto;
      @include media('<=1000px') {
        font-size: 1.4rem;
        line-height: 48px;
      }
      }
    }
  }



  .upgrade-circle-details {
    position: absolute;
    left:0;
    margin-top: 1rem;
    @include media('<=1000px') {
      margin-top: 0.1rem;
      
    }
    img {

      margin-top: 1rem;
      width: 100%;
    }
  }
  .supply-minting-modal {

    .info-text {
      font-family: 'Nanum Gothic Coding', monospace;
      text-align: right;
      margin-bottom: 0.5rem;
      margin-top: -0.5rem;
      color: #bababa;
      font-size: 1rem;
    }
    
    @media (max-width: 1000px) {
       .info-text {
        font-size: 0.9rem;
        text-align: center;
      }
    }


    .circle-prices {
      max-height: 450px;
      overflow-y: auto;
      ul {
        
        
        
      }
      .price-option {
        &.cant-afford {
          pointer-events: none;
        }
        ul {
          li {
            padding-left: 18px;
    text-indent: -18px;
          }
        }
        label {
          display: block;
          position: relative;
          padding: 0.5rem 0;
          cursor: pointer;
          padding-left: 2rem;
          border-top: 1px solid #413721;
          input {
            opacity: 0;
          }

          .check-box {
            width: 16px;
            height: 16px;
            display: inline-block;
            background-color: #000;
            vertical-align: bottom;
            border: 3px solid #000;
            box-sizing: border-box;
            opacity: 0.6;
            margin-right: 0.3rem;
            }

            &.active {
                .check-box {
                    background-color: #4e4631;
                  
                }
            }


        }
        
        &:last-child {
          border-bottom: 1px solid #413721;
        }
        .check-box {
          position: absolute;
          left: 0.2rem;
          top: 0.9rem;
        }
      }
    }
  }

  .cant-afford {
    .required-tokens {
      opacity: 0.4;
    }
  }

  .required-tokens {
    display: grid;
    gap: 1rem;
    padding: 1rem 1rem 1rem 0;
    font-family: 'Nanum Gothic Coding', monospace;
    @include media('<=1000px') {
      gap: 0.5rem;
      
    }
    grid-template-columns: 1fr 1fr 1fr;
    .figure-display {
      margin: 0 !important;
      box-shadow: none !important;
    }
    .note {
      padding: 0.5rem 0 0 0;
      font-size: 1rem;
      display: block;
      @include media('<=1000px') {
        font-size: 0.75rem;
        
      }
    }
  }

  .figure-requirement {
    position: relative;
    button {
      height: 24px;
      width: 24px;
      padding: 0;
      border: 0;
      opacity: 0.75;
      background-color: #000;
      border-radius: 100%;
      top: -12px;
      right: -12px;
      position: absolute;
      cursor: pointer;
      @include media('<=1000px') {
          height: 24px;
          width: 24px;
          top: -12px;
          right: -12px;
          
      }
      svg {
          width: 16px;
          height: 16px;
          @include media('<=1000px') {
              width: 16px;
              height: 16px;
          }
          path {
              fill: #ccc;
          }
      }
  }
  }