@import '../../../../global.scss';

.block-board {
    position: relative;
    margin-bottom: 60px;
    &.empty {
        height: 0;
        padding-top: 100%;
        .board-progress {
            display: none;
        }
        .block-board-grid {
            display: none;
        }
    }
    .board-progress {
        position: absolute;
        width: 50%;
        .total-progress {
            width: 100%;
            margin-bottom: 0;
        }
        .totals {
            font-family: 'Nanum Gothic Coding', monospace;
            display: block;
            font-size: 0.9rem;
            color: #bab1a0;
            opacity: 0.6;
        }
    }
    
    .border {
        opacity: 0.3;
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
    }
    .block-board-grid {
        display: grid;
        padding: 4px;
        box-sizing: border-box;
        background-color: #0000007d;
        .board-slot-socket {
            height: 0;
            padding-top: 100%;
            position:relative;
            &.slot-open {
                opacity: 0.5;
            }
        }
        .circle-slot {
            // height: 0;
            // padding-top: 100%;
            // position:relative;
            .figure-display {
                position: absolute;
                top: 0;
                left: 0;
                //width: 100%;
            }
        }
        .board-slot {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            .slot-power-glow {
                display: none;
            }
            button {
                height: 36px;
                width: 36px;
                padding: 0;
                border: 0;
                opacity: 0.75;
                background-color: #000;
                border-radius: 100%;
                top: -18px;
                right: -18px;
                position: absolute;
                cursor: pointer;
                @include media('<=1000px') {
                    height: 24px;
                    width: 24px;
                    top: -12px;
                    right: -12px;
                    
                }
                svg {
                    width: 24px;
                    height: 24px;
                    @include media('<=1000px') {
                        width: 16px;
                        height: 16px;
                    }
                    path {
                        fill: #ccc;
                    }
                }
            }
            .slot-hit-box {
                &.open {
                    cursor: pointer;
                }
            }
        }
    }
}