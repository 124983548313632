@import '../../global.scss';


$circleLarge : 65;
$circleMedium : 55;
$circleSmall : 55;
$circleTiny : 55;

.circle {
    
    position:absolute;
    left : 0;
    top: 0;
    bottom: 0;
    right: 0;
    // left: 50%;
    // top: 46%;
    // transform: translate3d(-50%, -50%, 0);

    // overflow: visible;
    // width: $circleLarge * 1vw;
    // height: $circleLarge * 1vh;
    // max-width: $circleLarge * 1vh;
    // max-height: $circleLarge * 1vw;
    // @include media('<=desktop-large') {
    //     width: $circleMedium * 1vw;
    //     height: $circleMedium * 1vh;
    //     max-width: $circleMedium * 1vh;
    //     max-height: $circleMedium * 1vw;
    // }
    // @include media('<=desktop') {
    //     width: $circleSmall * 1vw;
    //     height: $circleSmall * 1vh;
    //     max-width: $circleSmall * 1vh;
    //     max-height: $circleSmall * 1vw;
    // }
    // @include media('<=tablet') {
    //     width: $circleTiny * 1vw;
    //     height: $circleTiny * 1vh;
    //     max-width: $circleTiny * 1vh;
    //     max-height: $circleTiny * 1vw;
    // }
    canvas {
       // opacity: 0.07;
    }

    .canvas-wrapper {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
    }
    // .cicle-channels {
    //     position: relative;
    //     width: 65vw;
    //     height: 65vh;
    //     max-width: 65vh;
    //     max-height: 65vw;  
    // }
    
    
}

.circle-overlay {
    transform: translate3d(-50%, -50%, 0) rotate(180deg);
    position:absolute;
    left: 50%;
    top: 50%;
    
    .circle-channels {
        border-radius: 100%;
        overflow: hidden;
    }
}

.circle-channels {
    position:absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    .channel-grid {
        display: grid;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        grid-template-columns: 6.7% 1fr 6.7%;
        .grid-a, .grid-b {
            box-sizing: border-box;
            height: 0;
            padding-top: 375%;
            position: relative;
        }
    }

    .slot-wrapper {
        cursor: pointer;
        position: absolute;
        transform: translate3d(-50%, -50%, 0);
    }
    .circle-channel {
        
        
        
        .figure-item {
            z-index: 100;
            
        }
        &.in {
            
            .figure-display {
                box-shadow: -5px 5px 30px -1px #000;
            }
        }
        
        .elemental-flux {
            opacity: 0.1;
            border: 4px solid #ccc;
            height: 100%;
            display: block;
            transition: width 5s;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
        }
        
    }
    

    
}

.circle-channels {
    
}


@-moz-keyframes spin { 
    0% { -moz-transform: translate3d(-50%, -50%, 0) rotate3d(0,0,1, 0deg);  } 
    100% { -moz-transform: translate3d(-50%, -50%, 0) rotate3d(0,0,1,360deg);  } 
}
@-webkit-keyframes spin { 
    0% { -webkit-transform: translate3d(-50%, -50%, 0) rotate3d(0,0,1,0deg); }
    100% { -webkit-transform: translate3d(-50%, -50%, 0) rotate3d(0,0,1,360deg); } 
}
@keyframes spin { 
    0% { 
        -webkit-transform: translate3d(-50%, -50%, 0) rotate3d(0,0,1,0deg); 
        transform: translate3d(-50%, -50%, 0) rotate3d(0,0,1,0deg); 
    } 
    100% { 
        -webkit-transform: translate3d(-50%, -50%, 0) rotate3d(0,0,1,360deg); 
        transform: translate3d(-50%, -50%, 0) rotate3d(0,0,1,360deg); 
    } 
}