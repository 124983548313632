.focus-slot {
    position: relative;
    &:after {
        content: '';
        width: 50%;
        height: 50%;
        border-radius: 100%;
        border: 4px solid #ffffff15;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
    }
    &:hover {
        &:after {
            width: 100%;
            height: 100%;
        }
        
    }
    &.in {
            
        .figure-display {
            box-shadow: -5px 5px 30px -1px #000;
        }
    }
}