@import '../../global.scss';

.mastery-details {
    

    
    h2 {
        font-weight: 400;
    margin: 0;
    padding-bottom: 0.5rem;
    font-size: 1rem;
    text-transform: uppercase;
    color: #ccc;
    position: relative;
    margin-top: 2rem;
    &:after {
        
            content: '';
            position: absolute;
            bottom: -2px;
            left: 0;
            height: 2px;
            background-image: url('../../image/border-bottom.png');
            display: block;
            width: 100%;
            opacity: 0.5;
        
    }
    }
    .mastery-modifier {
        display: inline-block;
        font-family: 'Nanum Gothic Coding', monospace;
            vertical-align: top;
            margin-top: 0.25rem;
            background-color: #083800;
            padding: 0.25rem 0.5rem;
            border-radius: 5px;
            font-size: 0.9rem;
            font-weight: 500;
            transition: opacity 0.25s ease-in;
            color: #bababa;
            text-transform: uppercase;
            @include media('<=tablet') {
                font-size: 0.75rem;
                padding: 0.25rem 0.25rem;
                
             }
    }
    span.element-type {
        width: 12px;
        height: 12px;
        vertical-align: middle;
        display: inline-block;
        border-radius: 100%;
        box-shadow: inset 0px 2px 5px -2px #fff;
        box-sizing: border-box;
        @include media('<=1000px') {
            width: 10px;
            height: 10px;
        }
    }

    span {
        &.element-1 {
            background-color: $colorDeath;
        }
        &.element-2 {
            background-color: $colorLife;
        }
        &.element-3 {
            background-color: $colorFire;
        }
        &.element-4 {
            background-color: $colorWater;
        }
        &.element-5 {
            background-color: $colorEarth;
        }
        &.element-6 {
            background-color: $colorAir;
        }
        &.element-7 {
            background-color: $colorMemory;
        }
        &.element-8 {
            background-color: $colorVision;
        }
    }

    table {
        padding: 0;
        //margin: 0 1rem;
        width: 100%;
        font-family: 'Nanum Gothic Coding', monospace;
        font-size: 1.0rem;
        text-align: right;
        border-collapse: collapse;
        color: #888;
        @include media('<=1000px') {
            font-size: 0.75rem;
        }
        th {
            font-weight: normal;
            color: #555;
            text-transform: uppercase;
            padding: 1rem 0.25rem;
            &:first-child {
                padding: 1rem 0.25rem 1rem 1rem;
                text-align: left;
            }
            &:last-child {
                padding: 1rem 1rem 1rem 0.25rem;
            }
            &.sort-option {
                cursor: pointer;
                &.selected {
                    color: #ccc;
                }
            }
            &.align-center {
                text-align: center;
            }
            &.align-left {
                text-align: left;
            }
        }
        td {
            
            padding: 0.5rem 0.25rem;
            &:first-child {
                padding: 0.5rem 0.25rem 0.5rem 1rem;
                text-align: left;
            }
            &:last-child {
                padding: 0.5rem 1rem 0.5rem 0.25rem;
            }
            &.align-center {
                text-align: center;
            }
            &.align-left {
                text-align: left;
            }
        }
        tr {
            background-color: #0000006d;
            &:nth-child(odd) {
                td {
                    background-color: #111111;
                }
            }
            &.hilight {
                
                td {
                    
                    background: #3b372f;
background: linear-gradient(0deg, #4f4a40 0%, #3b372f 21%);
                }
            }
            
        }
    }
}

.mastery-section {
    .warning {
        background-color: #22201ca8;
        border: 1px solid #4d453a;
        box-sizing: border-box;
        padding: 1rem;
        @include media('<=1000px') {
            padding: 0.5rem;
        }
        h2 {
            font-weight: 400;
            margin-top: 0;
            margin-bottom: 0.5rem;
            @include media('<=1000px') {
                font-size: 1.2rem;
                margin-bottom: 0.5rem;
            }
        }
        p {
            margin: 0.5rem 0;
            color: #bababa;
            @include media('<=1000px') {
                font-size: 0.9rem;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
        margin-bottom: 1rem;
    }
}