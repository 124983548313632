@import '../../global.scss';

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.figure-slot-wrapper {
    position: relative;
    img {
        position: absolute;
        left: -50%;
        top: -50%;
        width: 100%;
        transform: translate3d(50%, 50%, 0);
        opacity: 0.25;
    }

    &.glow-1 {
        background-color: #000;
        background: radial-gradient(circle, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%);
      }
      
      &.glow-2 {
        background-color: #fff;
        background: radial-gradient(circle, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
      }
      
      &.glow-3 {
        background: radial-gradient(circle, rgba(182,0,0,0.5) 0%, rgba(182,0,0,0) 100%);
      }
      
      &.glow-4 {

        background: radial-gradient(circle, rgba(56,63,163,0.5) 0%, rgba(56,63,163,0) 100%);
      }
      
      &.glow-5 {
        background: radial-gradient(circle, rgba(13,80,22,0.5) 0%, rgba(13,80,22,0) 100%);
      }
      
      &.glow-6 {
        
        background: radial-gradient(circle, rgba(122,122,122,0.5) 0%, rgba(122,122,122,0) 100%);
      }
      
      &.glow-7 {
        
        background: radial-gradient(circle, rgba(182,74,2,0.5) 0%, rgba(182,74,2,0) 100%);
      }
      
      &.glow-8 {
        background: radial-gradient(circle, rgba(73,7,85,0.5) 0%, rgba(73,7,85,0) 100%);
      }
}

.figure-slot {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 100%;
   
    // @include media('<=1000px') {
    //     width: $figureSmall;
    //     height: $figureSmall;
    // }
   


     &.open {
        clip-path: polygon(100% 100%, 100% 0, 0 0, 0 100%, 100% 100%, 97% 97%, 3% 97%, 3% 3%, 97% 3%, 97% 97%, 100% 100%
        );
        &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
        }
        &:after {
            content: '';
            height: 200%;
            top: -50%;
            left: -50%;
            width: 200%;
            position: absolute;
            animation: rotate 5s linear infinite;
            opacity: 0.5;
            background: rgb(255,255,255);
            background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 33%, rgba(255,255,255,0.5018382352941176) 50%, rgba(255,255,255,0) 66%, rgba(255,255,255,0) 100%);
        }
     }
     .figure-display {
        box-shadow: -5px 5px 30px -1px #000;
     }
}