.slot-power-glow {
 

    -webkit-animation: fadeinslot 2s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadeinslot 2s; /* Firefox < 16 */
        -ms-animation: fadeinslot 2s; /* Internet Explorer */
         -o-animation: fadeinslot 2s; /* Opera < 12.1 */
            animation: fadeinslot 2s;
  }

  .board-slot {
      .token-user {
          position: absolute;
          bottom: -28px;
          line-height: 1rem;
          text-align: center;
          padding-top: 0.5rem;
          box-sizing: border-box;
          width: 200%;
          font-size: 1rem;
          left: 50%;
          transform: translateX(-50%);
          &.is-user {
              color: #a19679;
          }
      }
      .circle-slot {
        .masked {
          width: 100%;
          height: 0;
          padding-top: 100%;
          position: relative;
          overflow: hidden;
          .figure-display {
            position: absolute;
            left: 0;
            top:0;
            &:last-child {
              opacity: 0.7;
            }
          }
          
        }
      }
  }
  
  @keyframes fadeinslot {
    from { opacity: 0; }
    to   { opacity: 0.2; }
  }
  
  /* Firefox < 16 */
  @-moz-keyframes fadeinslot {
    from { opacity: 0; }
    to   { opacity: 0.2; }
  }
  
  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadeinslot {
    from { opacity: 0; }
    to   { opacity: 0.2; }
  }
  
  /* Internet Explorer */
  @-ms-keyframes fadeinslot {
    from { opacity: 0; }
    to   { opacity: 0.2; }
  }