@import '../../global.scss';

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
.mastery-timer {
    text-align: right;
    font-family: 'Nanum Gothic Coding', monospace;
}



.mastery-bar-wrapper {
    position: relative;
    color: #bababa;
    .mini-timer {
        margin-top: 1rem;
        position: absolute;
        left: 70px;
        font-family: 'Nanum Gothic Coding', monospace;
        text-transform: uppercase;
        .long-timer {
            display: inline-block;
        }
    }
    h2 {
        color: #fff;
        font-weight: 400;
        margin: 0;
        font-size: 1.2rem;
        margin-bottom: 0.5rem;
        @include media('<1000px') {
            font-size: 0.9rem;
        }
    }
    .next-level {
        text-align: right;
        .next-level-number {
            color: #666;
        }
    }
    .title-plate {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-left: 70px;
    }

    .research-cost-wrapper {
        padding: 1rem;
            background-color: #0000006d;

            @include media('<1000px') {
                padding: 0.5rem;
                font-size: 0.75rem;
            }
        .research-cost {
            
            &.not-enough {
                opacity: 0.4;
            }
        }
    }


    span.element-type {
        width: 12px;
        height: 12px;
        vertical-align: middle;
        display: inline-block;
        border-radius: 100%;
        box-shadow: inset 0px 2px 5px -2px #fff;
        box-sizing: border-box;
        @include media('<=1000px') {
            width: 10px;
            height: 10px;
        }
    }

    span {
        &.element-1 {
            background-color: $colorDeath;
        }
        &.element-2 {
            background-color: $colorLife;
        }
        &.element-3 {
            background-color: $colorFire;
        }
        &.element-4 {
            background-color: $colorWater;
        }
        &.element-5 {
            background-color: $colorEarth;
        }
        &.element-6 {
            background-color: $colorAir;
        }
        &.element-7 {
            background-color: $colorMemory;
        }
        &.element-8 {
            background-color: $colorVision;
        }
    }
    .research-session-button {
    }

    .session-button, .research-session-button {
        background-color: #4e4631;
        border: 1px solid #746849;
        color: #FFF;
        font-family: 'Nanum Gothic Coding', monospace;
        text-transform: uppercase;
        padding: 0.5rem 1rem;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 2px;
        transition: border 0.25s, background-color 0.25s;
        box-shadow: 0 0 14px -2px #000;
        &:hover {
            border: 1px solid #94845c;
            background-color: #685d42;
        }
        &:disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    .mastery-action-wrapper {
        margin-top: 1.8rem;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        box-sizing: border-box;
        padding: 0 1rem;
        @include media('<=1000px') {
            grid-template-columns: 1fr;
        }
        margin-bottom: 1rem;
    }

    .mastery-advanced {
        font-family: 'Nanum Gothic Coding', monospace;
        background-color: #0000006d;
        .session-label {
            text-transform: uppercase;
            text-align: left;
            margin-bottom: 0.5rem;
            display: block;
            -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
                                  @include media('<=1000px') {
                                    font-size: 0.9rem;
                                }
        }
        .figure-sockets {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            gap: 1rem;
            padding: 1rem;
            @include media('<=1000px') {
               gap: 0.5rem;
               padding: 0.5rem;
            }
            .figure-socket-wrapper {
                position: relative;
                cursor: not-allowed;
                svg {
                    opacity: 0.2;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate3d(-50%, -50%, 0);
                    path {
                        fill: #dbdbdb;
                    }
                }
            }
            .figure-socket {
                width: 100%;
                height: 0;
                padding-top: 100%;
                position: relative;
                
                &.open {
                    clip-path: polygon(100% 100%, 100% 0, 0 0, 0 100%, 100% 100%, 97% 97%, 3% 97%, 3% 3%, 97% 3%, 97% 97%, 100% 100%
                    );
                    &:before {
                        top: 0;
                        left:0;
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 100%;
                    }
                    &:after {
                        content: '';
                        height: 200%;
                        top: -50%;
                        left: -50%;
                        width: 200%;
                        position: absolute;
                        animation: rotate 5s linear infinite;
                        opacity: 0.5;
                        background: rgb(255,255,255);
                        background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 33%, rgba(255,255,255,0.5018382352941176) 50%, rgba(255,255,255,0) 66%, rgba(255,255,255,0) 100%);
                    }
                 }
            }
        }
    }

    .mastery-action {

        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem;
        font-family: 'Nanum Gothic Coding', monospace;
        align-content: start;



        .session-label {
            text-transform: uppercase;
            text-align: left;
            margin-bottom: 0.5rem;
            display: block;
            -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
                                  @include media('<=1000px') {
                                    font-size: 0.9rem;
                                }
        }
        .research-picker {
            display: grid;
            grid-template-columns: 30px 1fr 30px;
            align-items: center;
            
            button {
                height: 30px;
                margin: 0;
                padding: 0;
                box-sizing: border-box;
                cursor: pointer;
                svg {
                    margin-top: 3px;
                    height:20px !important;
                }
            }
            .picker-total {
                text-align: center;
                background-color: #0000006d;
                box-sizing: border-box;
                height: 100%;
                line-height: 30px;
                -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
                .session-number {
                    display: block;
                }
            }
        }
    }

    .research-timer {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 0.5rem;
        .panel {
            text-align: center;
            background-color: #0000006d;
            
            span {
                font-family: 'Nanum Gothic Coding', monospace;
                padding: 0.5rem;
                display: block;
                &:first-child {
                    font-size: 0.75rem;
                    color: #666;
                    padding-bottom: 0;
                }
                &:last-child {
                    font-size: 0.9rem;
                    color: #999;
                    font-weight: 100;
                }
            }
        }
    }
}

.mastery-bar {
    display: grid;
    grid-template-columns: 60px 1fr 30px;
    height: 36px;
    margin-right: -0.8rem;
    margin-left: -0.2rem;
    &.blocked {

    }
    .current-level {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
        font-size: 1.3rem;
        z-index: 1001;
        width: 100%;
        color: #bbb;
        text-align: center;
        margin-left: 9px;
        opacity: 0.8;
        text-shadow: 1px 1px 0px #000000, 2px 2px 0px #514a41;
        &.blocked {
            margin-top: 2px;
            opacity: 0.5;
        }
    }
    .mastery-bar-pane {
        position: relative;
        img {
            position: absolute;
            width: 130%;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1000;
        }
        &:after {
            content: '';
            box-shadow: 0 0 18px 12px #000;
            width: 60%;
            height: 100%;
            right: 0;
            position: absolute;
            z-index: 400;
        }
    }
    .mastery-bar-track {
        background-image: url('../../image/mastery-bar-bg.png');
        height: 100%;
        background-size: 100% 36px;
        box-shadow: 4px 1px 21px -1px #000;
        padding: 4px 0;
        box-sizing: border-box;
        position: relative;
        
        &:after {
            content: '';
            top: 4px;
            left: 0;
            right: 0;
            bottom: 4px;
            position: absolute;
            box-shadow: inset 0 0 10px 1px #000;
            border: 2px solid #000;
            z-index: 200;
        }
        
        .texture-overlay {
            
                
                position: absolute;
                left: 0;
                top: 4px;
                bottom: 4px;
                width: 100%;
                mix-blend-mode: multiply;
                opacity: 0.5;
                z-index: 100;
                background-size: auto;
                &:before {
                    content: '';
                    top: 4px;
                    left: 0;
                    right: 0;
                    bottom: 4px;
                    position: absolute;
                    background: rgb(255,255,255);
                    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 27%, rgba(255, 255, 255, 0) 65%);
                    z-index: 150;
                }
        }
        .segment-vial {
            left: 0;
            top: 4px;
            bottom: 4px;
            overflow: hidden;
            width: 0;
            position: absolute;
            &.training {
                width: 0;
                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    height: 100%;
                    box-shadow: 0 0 20px 10px #ffffff7d;
                    display: block;
                    width: 2px;
                    background-color: white;
                    opacity: 0.6;



                }
            }
            
        }
        .mastery-bar-segments {
            display: grid;
            grid-auto-columns: 1fr;
            grid-auto-flow:column;
            height: 100%;
            position: relative;
            
            overflow: hidden;
            
           
            .segment {
                text-align: center;
                position: relative;
                &:after {
                    content: '';
                    top: 0;
                    right: -2px;
                    height: 100%;
                    width: 0px;
                    border-left: 2px solid #eaeaea;
                    border-right: 2px solid #000000;
                    position: absolute;
                    display: block;
                    opacity: 0.1;
                    z-index: 500;
                
                }
                &:last-child {
                    &:after {
                        display: none;
                    }
                }
                
            }
        }
        .bar-fill {
            height: 100%;
        }
    }
    
    .mastery-bar-cap {
        height: 100%;
        position: relative;
        img {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            z-index:300;
        }
    }

    
}




$glowHeight: 25px;
.mastery-bar .mastery-bar-track {
.texture-overlay {
    &.element-1 {
        mix-blend-mode: normal;
                opacity: 0.2;
        background-image: url('../../image/exp-overlay-element-1.png');
    }
    &.element-2 {
        opacity: 0.9;
        background-image: url('../../image/exp-overlay-element-2.png');
    }
    &.element-3 {
        
        background-image: url('../../image/exp-overlay-element-3.png');
    }
    &.element-4 {
        background-image: url('../../image/exp-overlay-element-4.png');
    }
    &.element-5 {
        background-image: url('../../image/exp-overlay-element-5.png');
    }
    &.element-6 {
        background-image: url('../../image/exp-overlay-element-6.png');
    }
    &.element-7 {
        background-image: url('../../image/exp-overlay-element-7.png');
    }
    &.element-8 {
        mix-blend-mode: luminosity;
    opacity: 0.3;
        background-image: url('../../image/exp-overlay-element-8.png');
    }
}
}

.glow {
    position: absolute;
    left: 0;
    height: 100%;
    -webkit-animation: fadein 2s;
    /* Safari, Chrome and Opera > 12.1 */
    /* Firefox < 16 */
    /* Internet Explorer */
    /* Opera < 12.1 */
    animation: fadein 2s;
    &.glow-1 {
        box-shadow: 0 0 $glowHeight 5px $colorDeath;
    }
    &.glow-2 {
        box-shadow: 0 0 $glowHeight 5px $colorLife;
    }
    &.glow-3 {
        box-shadow: 0 0 $glowHeight 5px $colorFire;
    }
    &.glow-4 {
        box-shadow: 0 0 $glowHeight 5px $colorWater;
    }
    &.glow-5 {
        box-shadow: 0 0 $glowHeight 5px $colorEarth;
    }
    &.glow-6 {
        box-shadow: 0 0 $glowHeight 5px $colorAir;
    }
    &.glow-7 {
        box-shadow: 0 0 $glowHeight 5px $colorMemory;
    }
    &.glow-8 {
        box-shadow: 0 0 $glowHeight 5px $colorVision;
    }
}

.glow-start, .glow-start {
    &.glow-1 {
        box-shadow: 0 0 80px 20px $colorDeath;
    }
    &.glow-2 {
        box-shadow: 0 0 80px 20px $colorLife;
    }
    &.glow-3 {
        box-shadow: 0 0 80px 20px $colorFire;
    }
    &.glow-4 {
        box-shadow: 0 0 80px 20px $colorWater;
    }
    &.glow-5 {
        box-shadow: 0 0 80px 20px $colorEarth;
    }
    &.glow-6 {
        box-shadow: 0 0 80px 20px $colorAir;
    }
    &.glow-7 {
        box-shadow: 0 0 80px 20px $colorMemory;
    }
    &.glow-8 {
        box-shadow: 0 0 80px 20px $colorVision;
    }
}

.sphere-glow {
    width: 42px;
    height: 42px;
    display: block;
    position: absolute;
    z-index: 1000;
    right: 0;
    top: -3px;
    border-radius: 100%;
    opacity: 0.5;
    box-shadow: inset 0px -4px 15px 3px #000;
    &.element-1 {
        background-color: $colorDeath;
    }
    &.element-2 {
        background-color: $colorLife;
    }
    &.element-3 {
        background-color: $colorFire;
    }
    &.element-4 {
        background-color: $colorWater;
    }
    &.element-5 {
        background-color: $colorEarth;
    }
    &.element-6 {
        background-color: $colorAir;
    }
    &.element-7 {
        background-color: $colorMemory;
    }
    &.element-8 {
        background-color: $colorVision;
    }
}


  
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }


  
      
