@import '../../../global.scss';


.checklist {
    background-color: black;
    position: relative;
    z-index: 100;
    padding-bottom: 1rem;

    .experiment-content {
        max-width: 1200px;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr 2fr;
        column-gap: 2rem;
        row-gap: 1rem;
        padding: 2rem;
        .world-board-wrapper {
            grid-row: 1 / 3;
            grid-column: 2 / 3;
        }
        
        @include media('<=tablet') {
            grid-template-columns: 1fr;
            //grid-auto-flow: sparse;
            gap: 1rem;
            padding: 1rem;
            .world-board-wrapper {
                grid-row: auto;
                grid-column: auto;
            }
        }
        
    }

    .task-menu {
        //background-color: #000000b3;
        //padding: 0 1rem;
        padding: 0.5rem 0 0;
        ul {
            max-width: 1200px;
            margin: 0 auto;
            list-style-type: none;
            
            padding: 0;
            font-size: 0;
            li {
                
                display: inline-block;
                text-transform: uppercase;
                
                font-weight: 400;
                color: #bababa;
                &:first-child {
                    a {
                        padding-left: 0;
                    }
                }
                 a {
                     text-decoration: none;
                     font-family: 'Nanum Gothic Coding', monospace;
                     display: block;
                     padding: 0.5rem;
                     transition: color 0.25s ease-in;
                     color: #888;
                     font-size: 1.2rem;
                     &:hover {
                         color: #fff;
                     }
                     @include media('<=tablet') {
                        font-size: 1rem;
                        padding: 0.75rem;
                     }
                 }
            }
        }
    }
    .task-description {
        // padding: 2rem;
        // @include media('<=tablet') {
        //     padding: 1rem;
        // }
        h1 {
            margin: 0;
            font-size: 2rem;
            font-weight: 400;
            opacity: 1;
            transition: opacity 5s ease-in;
            @include media('<=tablet') {
                font-size: 1.8rem;
            }
        }
        p {
            font-size: 1.2rem;
            margin: 0 0 1rem 0;
            color: #bababa;
            @include media('<=tablet') {
                font-size: 1rem;
            }
            &:last-child {
                margin: 0;
            }
        }
        .task-detail {
            font-size: 1.2rem;
            color: #bababa;
            text-transform: uppercase;
            opacity: 0.7;
        }
        .user-ranking {
            
            font-size: 1.2rem;
            display: grid;
            grid-template-columns: 1fr 60px 100px;
            line-height: 1.2rem;
            .user-name {
                font-size: 1.2rem;
                
            }
            .user-rank {
                font-size: 1rem;
                font-family: 'Nanum Gothic Coding', monospace;
                text-align: right;
                .user-rank-number {
                    color: #888;
                }
            }
            .user-additional {
                font-size: 1rem;
                font-family: 'Nanum Gothic Coding', monospace;
                text-align: right;
                color: #888;
            }
        }
    }

        
    &:after {
        background-image: var(--task-background-image);
        background-size: cover;
        background-position: center 20%;
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: 0.1;
        pointer-events: none;
        display: block;
        z-index: -1;
    }

    .checklist-items {
        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
            li {
                font-size: 1.2rem;
                margin: 1.5rem 0;
                padding-left: 2.5rem;
                position: relative;
                color: #bababa;
                &:before {
                    content : '';
                    width: 24px;
                    height: 24px;
                    position: absolute;
                    left: 0;
                    top: 0;
                    border: 1px solid #333;
                    border-radius: 3px;
                    background-color: #111;
                    box-shadow: inset 1px 1px 5px 1px #000;
                    text-align: center;
                }
                &.complete {
                    color: #fff;
                    &:before {
                        content : 'X';
                    }
                }
                .finished {
                    color: #555;
                    font-family: 'Nanum Gothic Coding', monospace;
                }
            }
        }
    }
}