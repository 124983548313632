@import '../../global.scss';

.discovery-page {
    height: 100%;
    background-image: url('../../image/stone-darkest.png');
    position: relative;
    overflow: hidden;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 60px;
        background: rgb(0, 0, 0);
        background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    }

    &:before {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -15%;
        z-index: 1;
        padding-top: 1369px;
        width: 1969px;
       
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url('../../image/ink-bg.png');
        @include media('<=900px') {
            max-width: auto;
            padding-top: 1200px;
            width: 900px;
            max-width: 1600px;
            background-size: auto 100%;
            background-position: top center;
        }
    }

    .discovery-header {
        text-align: center;
        box-sizing: border-box;
        position: relative;
        z-index: 300;
        padding: 1rem;
        padding-bottom: 0;
        width: 100%;
        background: rgb(0, 0, 0);
        background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);

        &:after {
            position: absolute;
            content: '';
            bottom: 0;
            height: 2px;
            left: 0;
            right: 0;
            background-image: url('../../image/border-bottom.png');
        }

        img {
            display: inline-block;
            vertical-align: middle;

            &.discovery {
                margin-top: 0.5rem;
                max-width: 40%;
                width: 200px;
            }

            &.the-gold-crow {
                max-width: 12%;
                width: 50px;
                margin-right: 1rem;
            }
        }

        .discovery-nav {
            position: relative;

            ul {
                margin: 0;
                padding: 0;
                list-style-type: none;

                li {
                    a {
                        font-size: 1rem;
                        padding: 0 1rem;
                        color: #777;
                        text-decoration: none;
                        text-transform: uppercase;
                        line-height: 60px;
                        font-weight: 500;
                        display: block;

                        @include media('<=900px') {
                            text-align: left;
                            padding: 0 0.35rem;
                            text-transform: uppercase;
                            color: #777;
                            text-decoration: none;
                            line-height: 45px;
                            font-size: .85rem;

                        }
                    }

                    display: inline-block;
                }
            }
        }
    }

    .status-wrapper {
        text-align: center;
        margin-bottom: 0.5rem;

       
    }

    .status-tag {
        display: inline-block;
        font-family: 'Nanum Gothic Coding', monospace;
        text-transform: uppercase;
        background-color: #5e5847;
        padding: 0.25rem 0.5rem;
        border-radius: 5px;
        font-size: 0.9rem;
        font-weight: 500;
        transition: opacity 0.25s ease-in;
        color: #bababa;

        @include media('<=tablet') {
            font-size: 0.75rem;
            top: 0px;
            padding: 0.25rem 0.25rem;
            top: auto;
            bottom: 0;
        }
    }


    .discovery-body {
        padding: 2rem 1rem;
        box-sizing: border-box;

        .discovery-details {
            text-align: center;
            gap: 1rem;
            margin-bottom: 1rem;
            display: grid;
            grid-template-columns: 1fr;

            z-index: 200;
            position: relative;

            @include media('<=900px') {
                grid-template-columns: 1fr;
            }

            P {
                color: #bababa;
                margin-top: 0;

                &:last-child {
                    margin-bottom: 0;
                }
            }


        }

        .figure-display {
            position: relative;
            z-index: 100;
            box-shadow: 0 0 100px 50px #000;
        }

        .figure-detail {
            position: relative;
            z-index: 200;

        }

        h1 {
            font-weight: 100;
            font-size: 2.5rem;
            margin: 0;
            line-height: 2rem;
            margin-bottom: 1rem;

            @include media('<=900px') {
                font-size: 2rem;
                margin-bottom: 0.5rem;
            }
        }


    }

    .form-error-message {
        font-size: 1rem;
        color: #ff3636;
       margin: 1rem 2rem;
    }

    .claim-message {
        font-size: 1rem;
        color: #f4e2ad;
        margin: 1rem 2rem;
        position: relative;
        z-index: 400;
        text-align: center;
        border: 1px solid #f4e2ad;
        padding: 1rem;
        box-sizing: border-box;
        text-transform: uppercase;
        opacity: 0.5;
        border-radius: 5px;
    }


    .page-error-message {
        
        font-size: 1rem;
        color: #f4e2ad;
        margin: 2rem 2rem;
        position: relative;
        z-index: 400;
        text-align: center;
        border: 1px solid #f4e2ad;
        padding: 1rem;
        box-sizing: border-box;
        text-transform: uppercase;
        opacity: 0.5;
        border-radius: 5px;
    }

    .discovery-reward {
        margin: 2rem auto;
        max-width: 500px;
    }

    .discovery-wrapper {
        width: 100%;
        max-width: 500px;
        margin: 0 auto;
        position: relative;
        z-index:400;
    }

    .discovery-claim {
        position: relative;
        z-index: 400;
        margin-bottom: 2rem;
        text-align: center;

        .connected-user-button {
            border: 0;
            display: inline-block;
            font-family: 'Nanum Gothic Coding', monospace;
            background-color: transparent;
            color: #bababa;
            cursor: pointer;
        }

        label {
            display: block;
            text-align: center;
            font-size: 1rem;
            line-height: 45px;
        }

        input {
            background-color: transparent;
            width: 100%;
            border: 0px;
            outline: none;
            color: #fff;
            padding: 0.5rem 1rem;
            font-size: 1rem;
            font-family: 'Nanum Gothic Coding', monospace;
            box-sizing: border-box;
            border-bottom: 1px solid #5e5847;
            text-align: center;
        }
    }

    .discovery-expire {
        position: relative;
        z-index: 400;

        .expires-label {
            font-family: 'Nanum Gothic Coding', monospace;
            color: #bababa;
            text-transform: uppercase;
            text-align: center;
            margin-bottom: 0.5rem;
            display: block;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            user-select: none;
        }
    }

    .discovery-claimed {
        position: relative;
        z-index: 400;
        margin: 0 auto;
        max-width: 500px;
        padding: 0.5rem;
        box-sizing: border-box;

        h2 {
            font-weight: 100;
            margin: 0;
            margin-bottom: 0.5rem;
        }

        p {
            margin: 0;
        }
    }

    .button-wrapper {
        margin-top: 1rem;
        text-align: center;
    }

    .claim-button {
        background-color: #4e4631;
        border: 1px solid #746849;
        color: #FFF;
        font-family: 'Nanum Gothic Coding', monospace;
        text-transform: uppercase;
        padding: 0.5rem 1rem;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 2px;
        transition: border 0.25s, background-color 0.25s;
        box-shadow: 0 0 14px -2px #000;

        &:hover {
            border: 1px solid #94845c;
            background-color: #685d42;
        }

        &:disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    table {
        position: relative;
        z-index: 300;
        width: 100%;
        font-family: 'Nanum Gothic Coding', monospace;
        font-size: 1.0rem;
        text-align: left;
        border-collapse: collapse;
        color: #888;

        th {
            font-weight: normal;
            color: #555;
            text-transform: uppercase;
            padding: 1rem 0.25rem;

            &:first-child {
                padding: 1rem 0.25rem 1rem 1rem;
            }

            &:last-child {
                padding: 1rem 1rem 1rem 0.25rem;
            }

            &.sort-option {
                cursor: pointer;

                &.selected {
                    color: #ccc;
                }
            }
        }

        td {
            padding: 0.5rem 0.25rem;

            &:first-child {
                padding: 0.5rem 0.25rem 0.5rem 1rem;
            }

            &:last-child {
                padding: 0.5rem 1rem 0.5rem 0.25rem;
            }
        }

        tr {
            td {
                background-color: #000;

                span {
                    display: inline-block;
                }
            }

            &:nth-child(odd) {
                td {
                    background-color: #111;
                }
            }

            &.current-user {

                td {

                    background: #3b372f;
                    background: linear-gradient(0deg, #4f4a40 0%, #3b372f 21%);
                }
            }

        }
    }


    .discovery-the-keepers {
        position: relative;
        margin: 0 auto;
        text-align: center;
        z-index: 1;
        margin-bottom: 2rem;
        opacity: 0.5;
        
    }

    .research-timer {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 0.5rem;

        .panel {
            text-align: center;
            background-color: #000000;

            span {
                font-family: 'Nanum Gothic Coding', monospace;
                padding: 0.5rem;
                display: block;

                &:first-child {
                    font-size: 0.75rem;
                    color: #666;
                    padding-bottom: 0;
                }

                &:last-child {
                    font-size: 0.9rem;
                    color: #999;
                    font-weight: 100;
                }
            }
        }
    }
}