@import '../../global.scss';

.mastery-page {
    background-image: url('../../image/stone-dark.png');
    min-height: 100%;
    
    .mastery-section {
        margin: 1rem 0;
    }

    .total-mastery-levels {
        font-family: 'Nanum Gothic Coding', monospace; 
        color: #bababa;
        text-transform: uppercase;
    }
    .mastery-modifier {
        display: inline-block;
        font-family: 'Nanum Gothic Coding', monospace;
            vertical-align: top;
            margin-top: 0.25rem;
            background-color: #083800;
            padding: 0.25rem 0.5rem;
            border-radius: 5px;
            font-size: 0.9rem;
            font-weight: 500;
            transition: opacity 0.25s ease-in;
            color: #bababa;
            text-transform: uppercase;
            @include media('<=tablet') {
                font-size: 0.75rem;
                padding: 0.25rem 0.25rem;
                
             }
    }
    .research-pending-tag {
        font-family: 'Nanum Gothic Coding', monospace;
            position: absolute;
            top: 4px;
            right: 0;
            background-color: #5e5847;
            padding: 0.25rem 0.5rem;
            border-radius: 5px;
            font-size: 0.9rem;
            font-weight: 500;
            transition: opacity 0.25s ease-in;
            color: #bababa;
            text-transform: uppercase;
            @include media('<=tablet') {
                font-size: 0.75rem;
                padding: 0.25rem 0.25rem;
                
             }
        
    }
    .mastery-menu {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
        margin-top: 2rem;
        @include media('<=1000px') {
            grid-template-columns: 1fr;
            gap: 1rem;
        }
        .mastery-menu-item {
            .mastery-select {
                position: relative;
                cursor: pointer;
            }
            .mastery-title {
                font-weight: 400;
                font-size: 1.4rem;
                margin: 0;
                
                margin-bottom: 1rem;
                color: #bababa;
            }
            .view-mastery {
                text-align: right;
                font-family: 'Nanum Gothic Coding', monospace;
                font-size: 1.0rem;
                margin-top: 1rem;
                a {
                    text-decoration: none;
                    text-transform: uppercase;
                    color: #888;
                    transition: color 0.25s ease;
                    &:hover {
                        color: #fff;
                    }
                }
            }
        }
    }
}