@import '../../global.scss';




.task-history-page {

    

    h2 {
        font-weight: 400;
        margin: 0;
        margin-bottom: 0.5rem;
        font-size: 1.4rem;
        text-transform: uppercase;
        line-height: 2.4rem;
    }
    .task-rankings-page-content {
        position: relative;
    }
    
    table {
        width: 100%;
        font-family: 'Nanum Gothic Coding', monospace;
        font-size: 1.0rem;
        text-align: left;
        border-collapse: collapse;
        color: #888;
        th {
            font-weight: normal;
            color: #555;
            text-transform: uppercase;
            padding: 1rem 0.25rem;
            &:first-child {
                padding: 1rem 0.25rem 1rem 1rem;
            }
            &:last-child {
                padding: 1rem 1rem 1rem 0.25rem;
            }
            &.sort-option {
                cursor: pointer;
                &.selected {
                    color: #ccc;
                }
            }
        }
        td {
            padding: 0.5rem 0.25rem;
            &:first-child {
                padding: 0.5rem 0.25rem 0.5rem 1rem;
            }
            &:last-child {
                padding: 0.5rem 1rem 0.5rem 0.25rem;
            }
        }
        tr {
            
            &:nth-child(odd) {
                td {
                    background-color: #111;
                }
            }
            &.current-user {
                
                td {
                    
                    background: #3b372f;
background: linear-gradient(0deg, #4f4a40 0%, #3b372f 21%);
                }
            }
            
        }
    }
    .total-rank {
        text-align: left;
        color: #c0b999d9;
    }
    .align-left {
        text-align: left;
    }
    .rank-username {
        color: #bababa;
        font-family: 'Cormorant', serif;
        font-size: 1.2rem;
        text-align: left;
    }
    .rank-percent {
        color: #c0b999d9;
    }
    .empty {
        color: #444;
    }
    .element-amount {
        font-size: 0.9rem;
        color: #bababa;
    }
    .element-rank {
        font-size: 0.85rem;
        margin-top: 0.5rem;
        
        display: block;

    }
    .end-of-list {
        font-family: 'Cormorant', serif;
        text-align: center;
        padding: 2rem;
        letter-spacing: 5px;
        font-weight: 600;
        color: #444;
    }

    .list-view {
        font-family: 'Nanum Gothic Coding', monospace;
        display: none;
        @include media('<=900px') {
            display: block;
        }
        .list-view-header {
            text-align: right;
            display: grid;
            grid-template-columns: 40px 1fr 60px;
            line-height: 1.4rem;
            color: #777;
            background-color: #0c0c0c;
            text-transform: uppercase;
            padding: 0.5rem 1rem;
            &.is-element {
               
                    display: grid;
                    grid-template-columns: 40px 1fr 80px 80px;
                
            }
            div {
                &:first-child {
                    text-align: left;
                }
                &:nth-child(2) {
                    text-align: left;
                }
            }

        }
        .list-view-menu {
            position: absolute;
            top: 0;
            right: 0;
            select {
                background-color: transparent;
                border: 1px solid #857a60;
                color: #857a60;
                font-family: 'Nanum Gothic Coding', monospace;
                text-transform: uppercase;
                cursor: pointer;
                font-size: 1rem;
                padding: 0.5rem;
                border-radius: 2px;
                outline: none;
            }
            margin-bottom: 1rem;
        }
        .list-view-sort {
            list-style-type: none;
            margin: 0;
            padding: 0;
        }
        .list-view-results {
            list-style-type: none;
            margin: 0;
            padding: 0;
        }
        ul {
            li {
                &:nth-child(odd) {
                    background-color: #111;
                }
                &.current-user {
                    background: #3b372f;
background: linear-gradient(0deg, #4f4a40 0%, #3b372f 21%);
                }
            }
        }
        .end-of-list {
            font-family: 'Cormorant', serif;
            text-align: center;
            padding: 2rem;
            letter-spacing: 5px;
            font-weight: 600;
            color: #444;
        }
    }
    .table-view {
        display: block;
        @include media('<=900px') {
            display: none;
        }
    }



    .completed-sets {
        max-width: 1200px;
        margin: 0 auto 0;
        background-color: #00000063;
        box-sizing: border-box;
        margin-bottom: 2rem;
        > ul {
            padding: 0;
            margin: 0;
            list-style-type: none;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 2rem;
            @include media('<=1000px') {
                gap: 1rem;
                grid-template-columns: 1fr 1fr;
            }
        }
        &.evidence {
            > ul {
                
                grid-template-columns: 1fr 1fr;
                gap: 2rem;
                @include media('<=1000px') {
                    gap: 1rem;
                    grid-template-columns: 1fr;
                }
            } 
        }

        .user-tokens {
            ul {
                list-style-type: none;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                padding: 0;
                margin: 0;
                gap: 1rem;
                @include media('<=1000px') {
                    gap: 1rem;
                    grid-template-columns: 1fr 1fr;
                }
            }
            .block-board-history-item {
                position: relative;
            }
            .special {
                position: absolute;
                opacity: 0.5;
                top: -12px;
                left: -12px;
                svg {
                    width: 16px !important;
                    height: 16px !important;
                }
            }
        }
    }
}

