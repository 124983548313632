@import '../../global.scss';

.slot-figure-options {

    
    position: fixed;
    z-index: 10003;
    bottom: 0;
    height: 100vh;
    right: 0;
    width: 430px;
    box-shadow: 0 0 57px 8px #000;
    box-sizing: border-box;
    background-image: url('../../image/stone-dark.png');
    @include media('<1000px') {
        height: 95vh;
        width: 100%;
        box-shadow: 0px -35px 57px 0px #000;
    }
    .slot-details {
      padding: 2rem;
    }
    .slot-details-swap {
      position: relative;
      display: grid;
      grid-template-columns: 1fr 2fr;
      gap: 1rem;
      padding: 1rem;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 50%;
        z-index: -1;
      }
      &.element-1 {
        &:after {
          

          background: rgba($colorDeath, .6);
          background: linear-gradient(0deg, rgba($colorDeath, 0) 0%, rgba($colorDeath, .3) 100%);
          
        }
      }
      &.element-2 {
        &:after {
          

          background: rgba($colorLife, .6);
          background: linear-gradient(0deg, rgba($colorLife, 0) 0%, rgba($colorLife, .1) 100%);
          
        }
      }
      &.element-3 {
        &:after {
          background: rgba($colorFire, .6);
          background: linear-gradient(0deg, rgba($colorFire, 0) 0%, rgba($colorFire, .1) 100%);
        }
      }
      &.element-4 {
        &:after {
          background: rgba($colorWater, .6);
          background: linear-gradient(0deg, rgba($colorWater, 0) 0%, rgba($colorWater, .1) 100%);
        }
      }
      &.element-5 {
        &:after {
          background: rgba($colorEarth, .6);
          background: linear-gradient(0deg, rgba($colorEarth, 0) 0%, rgba($colorEarth, .1) 100%);
        }
      }
      &.element-6 {
        &:after {
          background: rgba($colorAir, .6);
          background: linear-gradient(0deg, rgba($colorAir, 0) 0%, rgba($colorAir, .1) 100%);
        }
      }
      &.element-7 {
        &:after {
          background: rgba($colorMemory, .6);
          background: linear-gradient(0deg, rgba($colorMemory, 0) 0%, rgba($colorMemory, .1) 100%);
        }
      }
      &.element-8 {
        &:after {
          background: rgba($colorVision, .6);
          background: linear-gradient(0deg, rgba($colorVision, 0) 0%, rgba($colorVision, .1) 100%);
        }
      }
    }
    .slot-content {
      display: grid;
      grid-template-rows: 32px 1fr;
    }
    .slot-section {
      position: absolute;
      top: 64px;
      bottom: 0;
      left: 0;
      right: 0;
      @include media('<1000px') {
        top:48px;
      }
    }

    .swap-pane {
      display: grid;
      grid-template-rows: 1fr auto;
     .swap-select-wrapper {
       overflow:  auto;
     }
    }
    .slot-figure-nav {
      height: 64px;
      background-color: #000;
      @include media('<1000px') {
        height:48px;
      }
      .slot-nav {
        position: absolute;
        top: 0;
        width: 100%;
        .prev-button {
         
          margin: 0;
          padding: 0;
          background-color: transparent;
          border: 0;
          position: absolute;
          left: 0;
          top: 0;
          height:64px;
          width: 64px;
          @include media('<1000px') {
            width:48px;
            height:48px;
          }
          svg {
            margin-top: 4px;
            path {
              fill: #4e4631;
              
            }
          }
        }
        .next-button {
         
          margin: 0;
          padding: 0;
          background-color: transparent;
          border: 0;
          position: absolute;
          right: 0;
          top: 0;
          height: 64px;
          width: 64px;
          @include media('<1000px') {
            width:48px;
            height:48px;
          }
          svg {
            margin-top: 4px;
            path {
              fill: #4e4631;
              
            }
          }
        }
       }
      h1 {
        margin: 0;
        padding: 0;
        font-size: 32px;
        font-weight: 500;
        line-height: 64px;
        text-align: center;
        font-size: 1.6rem;
        @include media('<1000px') {
          line-height:48px;
          font-size: 1.4rem;
        }
        .slot-number {
          font-family: 'Nanum Gothic Coding', monospace;
    font-size: 1.4rem;
    color: #777;
    @include media('<1000px') {
      line-height:48px;
      font-size: 1.2rem;
    }
        }
      }
      ul {
        margin: 0;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        font-family: 'Nanum Gothic Coding', monospace;
        li {
          height: 32px;
          
        }
      }
    }
    .figure-select-close {
        position: absolute;
        width: 48px;
        height: 48px;
        left: -48px;
        @include media('<1000px') {
            left: auto;
            right: 0;
            top: -24px;
            width: 24px;
                height: 24px;
        }
        button {
            width: 48px;
            height: 48px;
            background-color: transparent;
            border: 0;
            padding: 0;
            @include media('<1000px') {
                width: 24px;
                height: 24px;
            }
            cursor: pointer;
            svg {
                path {
                    fill: #5e5645;
                }
            }
        }
    }
    .figure-scroll-wrapper {
        height: 100%;
        overflow: scroll;
        padding: 1rem;
    box-sizing: border-box;
    }
    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: grid;
        grid-template-columns: 1fr 1fr;
        @include media('<1000px') {
            grid-template-columns: 1fr 1fr;
        }
        gap: 1rem;
        li {
            
        }
    }

    
@media (min-width: 901px) {
    .figure-select-item.element-1:hover {
        .figure-display:after {
            border: 7px solid #000;
            -webkit-box-shadow: inset 0 0 10px 10px #000;
                    box-shadow: inset 0 0 10px 10px #000;
        }
    }
  }
  
  @media (min-width: 901px) {
    .figure-select-item.element-2:hover {
        .figure-display:after {
                border: 7px solid #FFF;
            -webkit-box-shadow: inset 0 0 10px 10px #FFF;
              box-shadow: inset 0 0 10px 10px #FFF;
        }
    }
  }
  
  @media (min-width: 901px) {
    .figure-select-item.element-3:hover {
        .figure-display:after {
      border: 7px solid #b60000;
      -webkit-box-shadow: inset 0 0 10px 10px #b60000;
              box-shadow: inset 0 0 10px 10px #b60000;
        }
    }
  }
  
  @media (min-width: 901px) {
    .figure-select-item.element-4:hover{
        .figure-display:after {
      border: 7px solid #383fa3;
      -webkit-box-shadow: inset 0 0 10px 10px #383fa3;
              box-shadow: inset 0 0 10px 10px #383fa3;
        }
    }
  }
  
  @media (min-width: 901px) {
    .figure-select-item.element-5:hover {
        .figure-display:after {
      border: 7px solid #0d5016;
      -webkit-box-shadow: inset 0 0 10px 10px #0d5016;
              box-shadow: inset 0 0 10px 10px #0d5016;
        }
    }
  }
  
  @media (min-width: 901px) {
    .figure-select-item.element-6:hover {
        .figure-display:after {
      border: 7px solid #7a7a7a;
      -webkit-box-shadow: inset 0 0 10px 10px #7a7a7a;
              box-shadow: inset 0 0 10px 10px #7a7a7a;
        }
    }
  }
  
  @media (min-width: 901px) {
    .figure-select-item.element-7:hover {
        .figure-display:after {
      border: 7px solid #b64a02;
      -webkit-box-shadow: inset 0 0 10px 10px #b64a02;
              box-shadow: inset 0 0 10px 10px #b64a02;
        }
    }
  }
  
  @media (min-width: 901px) {
    .figure-select-item.element-8:hover {
        .figure-display:after {
      border: 7px solid #490755;
      -webkit-box-shadow: inset 0 0 10px 10px #490755;
              box-shadow: inset 0 0 10px 10px #490755;
        }
    }
  }

  .figure-select-item {
      cursor: pointer;
      position: relative;
      .figure-display {
          position: relative;
      }
  };

  .figure-select-item {
      .figure-display:after {
    opacity: 0.4;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 1px solid rgba(0, 0, 0, 0);
    -webkit-transition: border 0.35s, -webkit-box-shadow 0.2s;
    transition: border 0.35s, -webkit-box-shadow 0.2s;
    transition: border 0.35s, box-shadow 0.2s;
    transition: border 0.35s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
    -webkit-box-shadow: inset 0 0 0 0 #FFF;
            box-shadow: inset 0 0 0 0 #FFF;
        }
  }


  .figure-detail {
    .figure-desc {
      font-size: 1rem;
    }
    .figure-bottom {
      font-size: 0.8rem;
    }
  } 

  .figure-remove-button {
    background-color: #4e4631;
    border: 1px solid #746849;
    color: #FFF;
    font-family: 'Nanum Gothic Coding', monospace;
    text-transform: uppercase;
    padding: 0.25rem 0.5rem;
    cursor: pointer;
    font-size: 0.9rem;
    border-radius: 2px;
    transition: border 0.25s, background-color 0.25s;
    box-shadow: 0 0 14px -2px #000;
    float: right;
    margin-top: 0.5rem;
  }
}